<template>
    <div class="row justify-content-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" v-if="!isLoad">
            <div v-if="slider.length" class="row justify-content-center mb-5">
                <div class="col-12">
                    <splide :options="banner">
                        <splide-slide v-for="(item, i) in slider" :key="i">
                            <div class="card course-slider rounded-lg border-0 banner-wrap overflow-hidden">
                                <div class="embed-responsive embed-responsive-16by9">
                                    <video width="100%" controls>
                                        <source :src=" item.path" type="video/mp4">
                                    </video>
                                </div>
                            </div>
                        </splide-slide>
                    </splide>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-12">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                        <ul class="nav nav-tabs d-flex align-items-center justify-content-between product-info-tab border-bottom-0 pl-2 pr-2" id="pills-tab" role="tablist">
                            <li class="active list-inline-item"><a class="fw-700 pb-sm-5 pt-sm-5 xs-mb-2 ml-sm-5 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase active" href="#navtabs0" data-toggle="tab">SHINE</a></li>
                            <li class="list-inline-item"><a class="fw-700 pb-sm-5 pt-sm-5 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#navtabs1" data-toggle="tab">GLORY</a></li>
                            <li class="list-inline-item"><a class="fw-700 pb-sm-5 pt-sm-5 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#navtabs2" data-toggle="tab">TRIUMPH</a></li>
                            <li class="list-inline-item"><a class="fw-700 pb-sm-5 pt-sm-5 xs-mb-2 mr-sm-5 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#navtabs3" data-toggle="tab">EXCLUSIVE</a></li>
                        </ul>
                    </div>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="navtabs0">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-xsss d-block"><b>SHINE</b></h2><hr></div>
                                <div class="card-body pb-0">
                                   <shine></shine>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="navtabs1">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-xsss d-block"><b>GLORY</b></h2><hr></div>
                                <div class="card-body pb-0">
                                    <glory></glory>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="navtabs2">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-xsss d-block"><b>TRIUMPH</b></h2><hr></div>
                                <div class="card-body pb-0">
                                    <triumph></triumph>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="navtabs3">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-xsss d-block"><b>EXCLUSIVE</b></h2><hr></div>
                                <div class="card-body pb-0">
                                    <exclusive></exclusive>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center">
            <div class="my-3 text-center">
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
    
</template>
<style scoped>
@media (max-width: 1199.98px){
    .course-slider {
        height: auto !important;
    }
}
</style>
<script>
import Shine from './Shine.vue'
import Glory from './Glory.vue'
import Triumph from './Triumph.vue'
import Exclusive from './Exclusive.vue'
import axios from 'axios'
export default {
    name: `Home`,
    components:{
        Shine,
        Glory,
        Triumph,
        Exclusive
    },
    data(){
        return {
            media: process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            slider: [],
            banner: {
                rewind : true,
                perPage : 1,
                autoplay : true,
                arrows : true,
                gap: '1rem',
                pagination: false
            },
        }
    },
    created() {
        this.getData()
    },
    methods: {
        async getData(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/slider`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.slider = res.data.data
                this.isLoad = false
            })
        },
    },
};
</script>