<template>
    <div class="row">
        <div class="col-12">
            <div class="form-group mt-3 p-3 border-light border p-2 shadow-xs bg-white rounded-lg">
                <form @submit.prevent="searchCourse()" class="row">
                    <div class="col-lg-9">
                        <div class="form-group icon-input mb-0">
                            <i class="ti-search font-xs text-grey-400"></i>
                            <input v-model="search" type="search" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100" placeholder="Type here what you are looking for....">
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <button type="submit" class="w-100 d-block btn-current rounded-lg font-xssss fw-600 ls-3 style1-input p-0 text-uppercase ">Search</button>
                    </div>
                </form>
            </div>
            <div class="row" v-if="!isLoad && !isEmpty">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-3 p-auto"  v-for="(item, index) in courses.data" :key="index">
                    <div class="card course-card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                        <div class="card-image w-100 mb-3 overflow-hidden rounded-lg">
                            <router-link :to="'/courses/' + item.slug" class="position-relative d-block">
                                <img :src="item.banner ? item.banner : 'https://via.placeholder.com/400x300.png'" alt="image" class="w-100">
                            </router-link>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xl-6 col-lg-12 col-md-12 col-6 pr-xl-0">
                                    <span class="w-auto font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-warning d-inline-block text-warning">{{item.category}} - {{item.level}}</span>
                                </div>
                                <span class="col-xl-6 col-lg-12 col-md-12 col-6 pl-xl-0 pr-0 font-xss fw-700 ls-2 lh-32 d-inline-block text-success text-xl-right ml-0"><span class="font-xsss">Rp.</span>{{ item.price | numFormat('0,0') }}</span>
                            </div>
                            <h4 class="fw-700 font-xss lh-28 mt-xl-3"><p class="mb-0 text-dark text-grey-900">{{item.name}}</p></h4>
                            <div class="row justify-content-between ml-0 mt-2">
                                <h6 class="font-xssss text-grey-500 fw-600 col-lg-6 col-md-6 col-sm-6 col-xs-6"><i class="text-current fas fa-user-graduate mr-2"></i>{{item.total_student}} Students</h6>
                                <h6 class="font-xssss text-grey-500 fw-600 col-lg-6 col-md-6 col-sm-6 col-xs-6"><i class="text-current fas fa-copy mr-2"></i>{{item.total_material}} Material</h6>
                                <h6 v-if="item.point_course && item.point_course.status == 1 && item.point_course.type == 'AMOUNT'" class="font-xssss text-info fw-600 col-lg-6 col-md-6 col-sm-6 col-xs-6"><i class="text-info fas fa-certificate mr-2"></i>{{item.point_course.amount_point | numFormat('0,0')}} Points</h6>
                                <h6 v-if="item.point_course && item.point_course.status == 1 && item.point_course.type == 'PERCENT'" class="font-xssss text-info fw-600 col-lg-6 col-md-6 col-sm-6 col-xs-6"><i class="text-info fas fa-certificate mr-2"></i>{{item.point_course.amount_point}}% Points</h6>
                            </div>
                            <hr>
                            <div class="text-center">
                                <router-link type="buttton" :to="'/courses/' + item.slug" class="btn btn-current p-3 fw-600 rounded-lg d-inline-block font-xssss">{{ item.owned ? 'Owned' : 'More' }}</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 mb-4" v-if="courses.data && courses.data.length">
                    <ul class="pagination pagination-center justify-content-center d-flex pt-5">
                        <li v-for="(link, index) in courses.links" :key="index" class="page-item m-1">
                            <button v-if="link.label === '&laquo; Previous'" v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" :disabled="link.url === null" @click="prevCourse()"><i class="ti-angle-left"></i></button>
                            <button v-else-if="link.label === 'Next &raquo;'" v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" :disabled="link.url === null" @click="nextCourse()"><i class="ti-angle-right"></i></button>
                            <button v-else v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" @click="changeCourse(link.label)">{{link.label}}</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row" v-if="isLoad || !courses.length">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center py-3">
                    <div class="my-3" v-if="isLoad">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>

                    </div>
                    <div v-if="!courses.length && !isLoad">
                        <img :src="'/images/empty.png'" v-if="isEmpty" alt="" width="300">
                        <h1 class="text-muted" v-if="isEmpty">Data don't exist</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            courses: {},
            search:'',
            isLoad: true,
            isEmpty: false,
            page: 1,
        }
    },
    created() {
        this.getCourses(this.page)
    },
    methods: {
        async getCourses(page){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/course?page=${parseInt(page)}&search=${this.search.toLowerCase().trim()}&category=EXCLUSIVE`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.isLoad = false
                this.courses = res.data
                this.isEmpty = !this.courses.data.length
                
            }).catch((err) => console.error(err.response))
        },
        searchCourse(){
            this.isLoad = true
            this.page = 1
            this.getCourses(this.page)
        },
        changeCourse(page) {
            this.page = parseInt(page)
            this.getCourses(this.page)
        },
        prevCourse() {
            if (this.page >= 1) {
                this.page -= 1
                this.getCourses(this.page)
            }
        },
        nextCourse() {
            if (this.courses.last_page > this.page) {
                this.page += 1
                this.getCourses(this.page)
            }
        },
    },
    computed: {
        fullpath: function() {
            return this.courses.data.map(function(item) {
                return process.env.VUE_APP_URL_CLOUD + item.banner;
            });
        }
    }
}
</script>