var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[(!_vm.isLoad)?_c('div',{staticClass:"col-xl-12 col-lg-12 col-md-12 col-sm-12"},[(_vm.slider.length)?_c('div',{staticClass:"row justify-content-center mb-5"},[_c('div',{staticClass:"col-12"},[_c('splide',{attrs:{"options":_vm.banner}},_vm._l((_vm.slider),function(item,i){return _c('splide-slide',{key:i},[_c('div',{staticClass:"card course-slider rounded-lg border-0 banner-wrap overflow-hidden"},[_c('div',{staticClass:"embed-responsive embed-responsive-16by9"},[_c('video',{attrs:{"width":"100%","controls":""}},[_c('source',{attrs:{"src":item.path,"type":"video/mp4"}})])])])])}),1)],1)]):_vm._e(),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-12"},[_vm._m(0),_c('div',{staticClass:"tab-content",attrs:{"id":"pills-tabContent"}},[_c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"navtabs0"}},[_c('div',{staticClass:"card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3"},[_vm._m(1),_c('div',{staticClass:"card-body pb-0"},[_c('shine')],1)])]),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"navtabs1"}},[_c('div',{staticClass:"card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3"},[_vm._m(2),_c('div',{staticClass:"card-body pb-0"},[_c('glory')],1)])]),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"navtabs2"}},[_c('div',{staticClass:"card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3"},[_vm._m(3),_c('div',{staticClass:"card-body pb-0"},[_c('triumph')],1)])]),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"navtabs3"}},[_c('div',{staticClass:"card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3"},[_vm._m(4),_c('div',{staticClass:"card-body pb-0"},[_c('exclusive')],1)])])])])])]):_c('div',{staticClass:"col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center"},[_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4"},[_c('ul',{staticClass:"nav nav-tabs d-flex align-items-center justify-content-between product-info-tab border-bottom-0 pl-2 pr-2",attrs:{"id":"pills-tab","role":"tablist"}},[_c('li',{staticClass:"active list-inline-item"},[_c('a',{staticClass:"fw-700 pb-sm-5 pt-sm-5 xs-mb-2 ml-sm-5 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase active",attrs:{"href":"#navtabs0","data-toggle":"tab"}},[_vm._v("SHINE")])]),_c('li',{staticClass:"list-inline-item"},[_c('a',{staticClass:"fw-700 pb-sm-5 pt-sm-5 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase",attrs:{"href":"#navtabs1","data-toggle":"tab"}},[_vm._v("GLORY")])]),_c('li',{staticClass:"list-inline-item"},[_c('a',{staticClass:"fw-700 pb-sm-5 pt-sm-5 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase",attrs:{"href":"#navtabs2","data-toggle":"tab"}},[_vm._v("TRIUMPH")])]),_c('li',{staticClass:"list-inline-item"},[_c('a',{staticClass:"fw-700 pb-sm-5 pt-sm-5 xs-mb-2 mr-sm-5 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase",attrs:{"href":"#navtabs3","data-toggle":"tab"}},[_vm._v("EXCLUSIVE")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body mb-3 pb-0"},[_c('h2',{staticClass:"fw-400 font-xsss d-block"},[_c('b',[_vm._v("SHINE")])]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body mb-3 pb-0"},[_c('h2',{staticClass:"fw-400 font-xsss d-block"},[_c('b',[_vm._v("GLORY")])]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body mb-3 pb-0"},[_c('h2',{staticClass:"fw-400 font-xsss d-block"},[_c('b',[_vm._v("TRIUMPH")])]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body mb-3 pb-0"},[_c('h2',{staticClass:"fw-400 font-xsss d-block"},[_c('b',[_vm._v("EXCLUSIVE")])]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-3 text-center"},[_c('div',{staticClass:"spinner-grow text-warning mx-3",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]),_c('div',{staticClass:"spinner-grow text-warning mx-3",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]),_c('div',{staticClass:"spinner-grow text-warning mx-3",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]),_c('div',{staticClass:"spinner-grow text-warning mx-3",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]),_c('div',{staticClass:"spinner-grow text-warning mx-3",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]),_c('div',{staticClass:"spinner-grow text-warning mx-3",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])
}]

export { render, staticRenderFns }